import {Annotation, ComposableMap, Geographies, Geography} from "react-simple-maps";
import {counties} from "../norway_counties";
import React from "react";
import CardHeader from "react-bootstrap/CardHeader";
import {Card} from "react-bootstrap";

const TempoMapIndicator = ({perYearAndPlace}) => {
// https://www.react-simple-maps.io/

    let data = {}

    Object.keys(perYearAndPlace.county).forEach(county => {
        data[county] = perYearAndPlace["county"][county];
    })

    const formatTime = (time) => {
        let hours = Math.floor(time / 60);
        let minutes = time % 60;
        return hours +"h " + (minutes > 0 ? minutes + "m" : "");
    }

    return (
        <Card>
            <CardHeader className="bg-light">Per place in {perYearAndPlace.year}</CardHeader>
            <Card.Body>
                <ComposableMap projection="geoAzimuthalEqualArea" width={300} height={300}
                               projectionConfig={{scale: 1000, rotate: [-10.0, -53.0, 0], center: [2, 12]}}>
                    <Geographies geography={counties} fill="#D6D6DA"
                                 stroke="#FFFFFF"
                                 strokeWidth={0.5}>
                        {({geographies}) =>
                            geographies.map((geo) => (
                                <Geography key={geo.rsmKey} geography={geo} style={{
                                    default: {

                                        outline: 'none'
                                    },
                                    hover: {
                                        outline: 'none'
                                    },
                                    pressed: {
                                        outline: 'none'
                                    },
                                }}/>
                            ))
                        }
                    </Geographies>
                    {data.more_og_romsdal && data.more_og_romsdal > 0 &&
                        <Annotation
                            subject={[7.5, 62.5]}
                            dx={-10}
                            dy={-5}
                            connectorProps={{
                                stroke: "#FF5533",
                                strokeWidth: 1,
                                strokeLinecap: "round"
                            }}
                        >
                            <text x="-8" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                {formatTime(data.more_og_romsdal)}
                            </text>
                        </Annotation>
                    }
                    {data.trondelag && data.trondelag > 0 &&
                        <Annotation
                            subject={[10.5, 64]}
                            dx={-10}
                            dy={0}
                            connectorProps={{
                                stroke: "#FF5533",
                                strokeWidth: 1,
                                strokeLinecap: "round"
                            }}
                        >
                            <text x="-8" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                {formatTime(data.trondelag)}
                            </text>
                        </Annotation>
                    }
                    {data.nordland && data.nordland > 0 &&
                        <Annotation
                            subject={[14.5, 67]}
                            dx={-20}
                            dy={0}
                            connectorProps={{
                                stroke: "#FF5533",
                                strokeWidth: 1,
                                strokeLinecap: "round"
                            }}
                        >
                            <text x="-8" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                {formatTime(data.nordland)}
                            </text>
                        </Annotation>
                    }
                    {data.troms_og_finnmark && data.troms_og_finnmark > 0 &&
                        <Annotation
                            subject={[22.5, 70]}
                            dx={-30}
                            dy={0}
                            connectorProps={{
                                stroke: "#FF5533",
                                strokeWidth: 1,
                                strokeLinecap: "round"
                            }}
                        >
                            <text x="-8" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                {formatTime(data.troms_og_finnmark)}
                            </text>
                        </Annotation>
                    }
                    {data.oslo && data.oslo > 0 &&
                        <Annotation
                            subject={[10.9, 59.955]}
                            dx={20}
                            dy={-10}
                            connectorProps={{
                                stroke: "#FF5533",
                                strokeWidth: 1,
                                strokeLinecap: "round"
                            }}
                        >
                            <text x="70" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                {formatTime(data.oslo)}
                            </text>
                        </Annotation>
                    }
                    {data.innlandet && data.innlandet > 0 &&
                        <Annotation
                            subject={[10.9, 61.5]}
                            dx={30}
                            dy={-10}
                            connectorProps={{
                                stroke: "#FF5533",
                                strokeWidth: 1,
                                strokeLinecap: "round"
                            }}
                        >
                            <text x="70" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                {formatTime(data.innlandet)}
                            </text>
                        </Annotation>
                    }
                    {data.viken && data.viken > 0 &&
                        <Annotation
                            subject={[9.8, 59.955]}
                            dx={30}
                            dy={20}
                            connectorProps={{
                                stroke: "#FF5533",
                                strokeWidth: 1,
                                strokeLinecap: "round"
                            }}
                        >
                            <text x="70" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                {formatTime(data.viken)}
                            </text>
                        </Annotation>
                    }
                    {data.vestfold_og_telemark && data.vestfold_og_telemark > 0 &&
                        <Annotation
                            subject={[9.2, 59.3]}
                            dx={30}
                            dy={30}
                            connectorProps={{
                                stroke: "#FF5533",
                                strokeWidth: 1,
                                strokeLinecap: "round"
                            }}
                        >
                            <text x="60" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                {formatTime(data.vestfold_og_telemark)}
                            </text>
                        </Annotation>
                    }
                    {data.rogaland && data.rogaland > 0 &&
                        <Annotation
                            subject={[6, 58.8]}
                            dx={-10}
                            dy={0}
                            connectorProps={{
                                stroke: "#FF5533",
                                strokeWidth: 1,
                                strokeLinecap: "round"
                            }}
                        >
                            <text x="-8" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                {formatTime(data.rogaland)}
                            </text>
                        </Annotation>
                    }
                    {data.agder && data.agder > 0 &&
                        <Annotation
                            subject={[7.3, 58.4]}
                            dx={-10}
                            dy={15}
                            connectorProps={{
                                stroke: "#FF5533",
                                strokeWidth: 1,
                                strokeLinecap: "round"
                            }}
                        >
                            <text x="-8" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                {formatTime(data.agder)}
                            </text>
                        </Annotation>
                    }
                    {data.vestland && data.vestland > 0 &&
                        <Annotation
                            subject={[6, 60.5]}
                            dx={-10}
                            dy={0}
                            connectorProps={{
                                stroke: "#FF5533",
                                strokeWidth: 1,
                                strokeLinecap: "round"
                            }}
                        >
                            <text x="-8" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                {formatTime(data.vestland)}
                            </text>
                        </Annotation>
                    }
                    {data.sweden && data.sweden > 0 &&
                        <Annotation
                            subject={[10.9, 59.955]}
                            dx={100}
                            dy={55}
                            connectorProps={{
                                stroke: "#FF5533",
                                strokeWidth: 0,
                                strokeLinecap: "round"
                            }}
                        >
                            <text x="70" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                Sweden {formatTime(data.sweden)}
                            </text>
                        </Annotation>
                    }
                    {data.home && data.home > 0 &&
                        <Annotation
                            subject={[10.9, 59.955]}
                            dx={100}
                            dy={40}
                            connectorProps={{
                                stroke: "#FF5533",
                                strokeWidth: 0,
                                strokeLinecap: "round"
                            }}
                        >
                            <text x="70" textAnchor="end" alignmentBaseline="middle" fill="#F53">
                                Home {formatTime(data.home)}
                            </text>
                        </Annotation>
                    }
                </ComposableMap>
            </Card.Body>
        </Card>

    )
}

export default TempoMapIndicator;