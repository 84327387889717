import TempoStatsIndicator from "./TempoStatsIndicator";

const TempoStats = ({tempoList, tempoSettings}) => {

    return (
        <>
            <TempoStatsIndicator tempoList={tempoList} tempoSettings={tempoSettings} />
        </>
    )
}

export default TempoStats;