import React, {useEffect, useState} from 'react';

import {db} from '../firebase-config';
import {collection, addDoc, doc, setDoc} from "firebase/firestore";
import {useUserAuth} from "../context/UserAuthContext";
import {Button, Form, Toast, ToastContainer} from "react-bootstrap";
import {Card, CardBody} from "reactstrap";

const getOverTime = (time) => {
    if (time) {
        let overTime = time - (7*60 + 30);
        if (overTime > 0) {
            return formatTime(overTime);
        }
        return "00:00"
    }
}

const toMinute = (time) => {
    let hours = parseInt(time.slice(0, 2));
    let minutes = parseInt(time.slice(3, 5));
    return hours * 60 + minutes;
}

const formatTime = (time) => {
    let hours = Math.floor(time / 60);
    let minutes = time % 60;
    return (hours <= 9 ? ("0" + hours) : hours) + ":" + (minutes <= 9 ? ("0" + minutes) : minutes);
}
const TempoForm = ({tempo, isFinishedSaving}) => {
    const [name, setName] = useState(tempo ? tempo.name : "")
    const [holiday, setHoliday] = useState(tempo ? tempo.holiday : false)
    const [task, setTask] = useState(tempo ? tempo.task : "")
    const [date, setDate] = useState(tempo ? tempo.date : new Date().toISOString().slice(0, 10))
    const [from, setFrom] = useState(tempo && tempo.from ? tempo.from: "09:00");
    const [to, setTo] = useState(tempo && tempo.to ? tempo.to: "16:30");
    const [duration, setDuration] = useState(tempo ? tempo.duration : "07:30")
    const [overtime, setOvertime] = useState(getOverTime(tempo ? tempo.duration : "07:30"))
    const [county, setCounty] = useState(tempo ? tempo.county : "trondelag")
    const [showError, setShowError] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState("");

    const toggleShowError = () => setShowError(false);
    const toggleShowMessage = () => {
        setShowMessage(false);
    }

    const displayMessage = (mess) => {
        setMessage(mess);
        setShowMessage(true);
    }


    const {user} = useUserAuth();

    useEffect(() => {
        if (tempo) {
            setName(tempo.name);
            setTask(tempo.task);
            setDate(tempo.date);
            setFrom(tempo.from);
            setTo(tempo.to);
            setDuration(tempo.duration);
            setHoliday(tempo.holiday);
            setCounty(tempo.county);
        }
    }, [tempo])

    const handleChangeName = (e) => {
        e.preventDefault(); // prevent the default action
        setName(e.target.value); // set name to e.target.value (event)
    };

    const handleChangeTask = (e) => {
        e.preventDefault();
        setTask(e.target.value);
    }

    const handleChangeDate = (e) => {
        e.preventDefault();
        setDate(e.target.value);
    }

    const handleChangeFrom = (e) => {
        e.preventDefault();
        setFrom(e.target.value);
        calculateDurationAndOvertime(e.target.value, to);
    }

    const handleChangeTo = (e) => {
        e.preventDefault();
        setTo(e.target.value);
        calculateDurationAndOvertime(from, e.target.value);
    }

    const calculateDurationAndOvertime = (start, end) => {
        let duration = toMinute(end) - toMinute(start);
        setDuration(formatTime(duration));
        setOvertime(getOverTime(duration));
    }

    const handleChangeCounty = (e) => {
        e.preventDefault();
        setCounty(e.target.value);
    }

    const handleChangeHoliday = (e) => {
        //e.preventDefault();
        setHoliday(e.target.checked);
    }

    const submit = async (e) => {
        e.preventDefault();

        if (!name || !date || !duration) {
            setShowError(true);
            return;
        }
        if (tempo) {
            updateWork();
        } else {
            addWork();
        }

        if (isFinishedSaving) {
            isFinishedSaving();
        }
    }
    const updateWork = async () => {
        const docRef = doc(db, "tempo", tempo.id);
        let now = Date.now();
        setDoc(docRef, {
            name: name,
            date: date,
            task: task,
            from: from,
            to: to,
            duration: duration,
            county: county,
            holiday: holiday,
            overtime:overtime,
            lastUpdate: now
        }, {merge: true})
            .then(docRef => {
                displayMessage("Update successfully")
            })
            .catch(error => {
                console.log(error);
            })
    }
    const addWork = async () => {

        try {
            let now = Date.now();
            const docRef = await addDoc(collection(db, "tempo"), {
                name: name,
                date: date,
                task: task,
                from: from,
                to: to,
                duration: duration,
                county: county,
                holiday: holiday,
                overtime:overtime,
                createdAt: now,
                owner: user.uid
            });
            displayMessage("Update successfully")
            setName("");
            setTask("");
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    return (
        <>
            <Card>

                <CardBody>
                    <Form>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control value={name} type="text" placeholder="Enter name"
                                          onChange={handleChangeName}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="forTask">
                            <Form.Label>Task</Form.Label>
                            <Form.Control value={task} type="text" placeholder="Enter task"
                                          onChange={handleChangeTask}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="forDate">
                            <Form.Label>Date</Form.Label>
                            <Form.Control value={date} type="date" placeholder="Enter date"
                                          onChange={handleChangeDate}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="forFrom">
                            <Form.Label>From</Form.Label>
                            <Form.Control type="time" value={from} placeholder="Enter from"
                                          onChange={handleChangeFrom}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="forTo">
                            <Form.Label>To</Form.Label>
                            <Form.Control type="time" value={to} placeholder="Enter to"
                                          onChange={handleChangeTo}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="forDuration">
                            <Form.Label>Duration</Form.Label>
                            <Form.Control type="time" value={duration} disabled/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="forOvertime">
                            <Form.Label>Overtime</Form.Label>
                            <Form.Control type="time" value={overtime} disabled/>
                        </Form.Group>

                        <Form.Group className="mb-5" controlId="forHoliday">
                            <Form.Label>Holiday</Form.Label>
                            <Form.Check
                                type="switch"
                                id="holiday"
                                checked={holiday}
                                onChange={handleChangeHoliday}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="forCountis">
                            <Form.Label>Place</Form.Label>
                            <Form.Select
                                value={county}
                                onChange={handleChangeCounty}
                            >
                                <option value="home">Home</option>
                                <option value="oslo">Oslo</option>
                                <option value="rogaland">Rogaland</option>
                                <option value="more_og_romsdal">Møre og Romsdal</option>
                                <option value="nordland">Nordland</option>
                                <option value="viken">Viken</option>
                                <option value="innlandet">Innlandet</option>
                                <option value="vestfold_og_telemark">Vestfold og Telemark</option>
                                <option value="agder">Agder</option>
                                <option value="vestland">Vestland</option>
                                <option value="trondelag">Trøndelag</option>
                                <option value="troms_og_finnmark">Troms og Finnmark</option>
                                <option value="sweden">Sweden</option>
                                <option value="other">Other</option>
                            </Form.Select>
                        </Form.Group>
                        <Button variant="primary" type="submit" onClick={submit}>
                            Submit
                        </Button>
                        {isFinishedSaving &&
                            <Button variant="primary" type="submit" onClick={isFinishedSaving}>
                                Cancel
                            </Button>
                        }
                    </Form>
                </CardBody>
            </Card>
            <ToastContainer className="p-3" position="top-end">
                <Toast show={showError} onClose={toggleShowError}>
                    <Toast.Header>
                        <strong className="me-auto">Error</strong>
                    </Toast.Header>
                    <Toast.Body>Please fill the form before creating a new work.</Toast.Body>
                </Toast>
            </ToastContainer>
            <ToastContainer className="p-3" position="top-end">
                <Toast show={showMessage} onClose={toggleShowMessage}>
                    <Toast.Header>
                        <strong className="me-auto">Info</strong>
                    </Toast.Header>
                    <Toast.Body>{message}</Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    )
}

export default TempoForm