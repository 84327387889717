import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAUGZncC8_lewmsnajb3gzbMZwwhvpEwE8",
    authDomain: "reminder-394cc.firebaseapp.com",
    projectId: "reminder-394cc",
    storageBucket: "reminder-394cc.appspot.com",
    messagingSenderId: "250252680369",
    appId: "1:250252680369:web:d3cd6feb5e1cd9f2361690",
    measurementId: "G-CS5JE4PRSB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

export default app;
