import {Button, Card} from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import {Bar, Chart} from 'react-chartjs-2';
import {getISOWeek} from 'date-fns';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend, PointElement, LineElement, LineController, BarController,
} from 'chart.js';
import {useState} from "react";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);
const TempoTimeDetailPerYear = ({perYearDetail}) => {

    const [turn, setTurn] = useState("true" === localStorage.getItem("tempo-rotate-" + perYearDetail.year));

    const toggleSwitch = () => {
        localStorage.setItem("tempo-rotate-" + perYearDetail.year, "" + !turn)
        setTurn(!turn);
    }

    const options = {
        indexAxis: !turn ? 'x' : 'y',
        plugins: {
            title: {
                display: true,
                text: `Work per week ${perYearDetail.year}`,
            },
        },
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
        maintainAspectRatio: !turn
    };

    // get all weeks between from date to end of year
    let start = new Date(perYearDetail.fromDate);
    let end = new Date();
    end.setFullYear(perYearDetail.year, 11, 31);
    let firstWeek = getISOWeek(start);
    let lastWeek = getISOWeek(end);

    let labels = [];
    let requiredHours = [];
    let workedHours = [];
    let holidayHours = [];
    let currentWeek = [];
    let fullYear = [];
    let sumWorked = 0;
    let avgWorked = [];
    let numberOfWeek = 0;
    let hoursPerWeekRequired = perYearDetail.hoursPerDay * 5 * perYearDetail.percentageRequired / 100;
    let extraFirstColumn = 0;
    let addExtraWeek = false;
    if (firstWeek === 52 || firstWeek === 53) {
        labels.push("w" + firstWeek);
        requiredHours.push(hoursPerWeekRequired);
        workedHours.push(0);
        holidayHours.push(0);
        currentWeek.push(0);
        fullYear.push(7.5 * 5);
        firstWeek = 1;
        extraFirstColumn = 1;
        addExtraWeek = true;
    }

    for (let week = firstWeek; week <= lastWeek; week++) {
        labels.push("w" + week);
        requiredHours.push(hoursPerWeekRequired);
        workedHours.push(0)
        holidayHours.push(0);
        currentWeek.push(0);
        fullYear.push(7.5 * 5);
    }

    var today = new Date();
    var thisWeek = getISOWeek(new Date());
    if (today.getMonth() === 0 && (thisWeek === 52 ||thisWeek === 53)) {
        currentWeek[0] = hoursPerWeekRequired;
        numberOfWeek = 1;
    } else {
        currentWeek[thisWeek - firstWeek + extraFirstColumn] = hoursPerWeekRequired;
        numberOfWeek = thisWeek + extraFirstColumn;
    }

    perYearDetail.details.forEach(detail => {
        let dateDetail = new Date(detail.date);

        let weekOfDetail = getISOWeek(dateDetail);

        let hours = parseInt(detail.duration.slice(0, 2));
        let minutes = parseInt(detail.duration.slice(3, 5));
        let duration = (hours + (minutes / 60));
        if (dateDetail.getMonth() === 0 && (weekOfDetail === 52 ||weekOfDetail === 53)) {
            if (detail.holiday) {
                holidayHours[0] += duration;
            } else {
                workedHours[0] += duration;
            }
        } else {
            if (detail.holiday) {
                holidayHours[weekOfDetail - firstWeek + extraFirstColumn] += duration;
            } else {
                workedHours[weekOfDetail - firstWeek + extraFirstColumn] += duration;
            }
        }

        sumWorked+= duration;

    })

    let avg = sumWorked / numberOfWeek;
    if (addExtraWeek) {
        avgWorked.push(avg);
    }
    for (let week = firstWeek; week <= lastWeek; week++) {
        avgWorked.push(avg);
    }

    let datasets = [];

    datasets.push({
        id: 3,
        type: 'line' ,
        label: 'AVG time',
        backgroundColor: 'rgb(245,99,255)',
        borderColor: 'rgb(245,99,255)',
        fill: false,
        data: avgWorked,
        stack: 'Stack 5'
    });

    datasets.push({
        id: 1,
        type: 'line' ,
        label: 'Full time',
        backgroundColor: 'rgb(99,255,255)',
        borderColor: 'rgb(99,255,255)',
        fill: false,
        data: fullYear,
        stack: 'Stack 4'
    });
    datasets.push(
    {
        id: 2,
        label: 'Worked',
        data: workedHours,
        backgroundColor: 'rgb(118,248,113)',
        grouped: false,
        categoryPercentage: 0.7,
        stack: 'Stack 0',
    });
    datasets.push(
        {
            id: 3,
            label: 'Holiday',
            data: holidayHours,
            backgroundColor: 'rgb(194,113,248)',
            grouped: false,
            categoryPercentage: 0.7,
            stack: 'Stack 0',
        });

    if (perYearDetail.year === new Date().getFullYear()) {
        datasets.push({
            id: 1,
            label: 'Current week',
            data: currentWeek,
            backgroundColor: 'rgb(113,140,248)',
            borderColor: 'rgb(2,84,246)',
            categoryPercentage: 1.1,
            grouped: false,
            stack: 'Stack 2',
        })

    }

    datasets.push(
        {
            id: 4,
            label: 'Required',
            data: requiredHours,
            backgroundColor: 'rgb(185,185,185)',
            grouped: false,
            stack: 'Stack 1',
        });

    const data = {
        labels,
        datasets: datasets,
    };


    return (
        <>
            <Card>
                <CardHeader className="bg-light">{perYearDetail.year} <Button style={{float:"right"}} onClick={toggleSwitch}>Rotate</Button></CardHeader>
                <Card.Body>
                    {turn &&
                        <Chart height={600} type='bar' options={options} data={data} />
                    }
                    {!turn &&
                        <Chart type='bar' options={options} data={data} />
                    }
                </Card.Body>
            </Card>
        </>
    )
}

export default TempoTimeDetailPerYear;