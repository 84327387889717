import {Col, Row} from "react-bootstrap";
import TempoTimeUsed from "./TempoTimeUsed";
import {useEffect, useState} from "react";
import TempoMapIndicator from "./TempoMapIndicator";
import TempoTimeDetailPerYear from "./TempoTimeDetailPerYear";

const TempoStatsIndicator = ({tempoSettings, tempoList}) => {

    const timePerDay = 7 * 60 + 30;
    const [perYearList, setPerYearList] = useState([])
    const [perYearAndPlace, setPerYearAndPlace] = useState([]);
    const [perYearDetails, setPerYearDetails] = useState([]);
    useEffect(() => {
        if (tempoList) {
            let perYearProv = {}
            let perYearPlaceProv = {};
            let perYearDetailsProv = {};
            tempoList.forEach(tempo => {
                let year = parseInt(tempo.date.slice(0, 4));
                let county = tempo.county || "not_defined";
                let hours = parseInt(tempo.duration.slice(0, 2));
                let minutes = parseInt(tempo.duration.slice(3, 5));

                if (!perYearProv[year]) {
                    perYearProv[year] = {year: year, minutes: 0, holidays: 0, overtime : 0}
                    let setting = tempoSettings.find(s => s.year === year);
                    if (setting) {
                        perYearProv[year]["required"] = parseInt(setting.hourRequired);
                        perYearProv[year]["holidays_available"] = setting.holidays * setting.hoursPerDay * setting.percentageRequired / 100;
                        perYearProv[year]["overtime_available"] = setting.overtimeDays * setting.hoursPerDay
                    }
                }
                if (tempo.holiday) {
                    perYearProv[year]["holidays"] += (hours * 60 + minutes);
                } else {
                    let time = hours * 60 + minutes;
                    perYearProv[year]["minutes"] += time;
                    if (time > (7 * 60 + 30)) {
                        perYearProv[year]["overtime"] += time - timePerDay;
                    }
                }

                if (!tempo.holiday) {
                    if (!perYearPlaceProv[year]) {
                        perYearPlaceProv[year] = {year: year, county: {}}
                    }
                    if (!perYearPlaceProv[year]["county"][county]) {
                        perYearPlaceProv[year]["county"][county] = 0;
                    }

                    perYearPlaceProv[year]["county"][county] += (hours * 60 + minutes);
                }
                if (!perYearDetailsProv[year]) {
                    perYearDetailsProv[year] = {year: year, details: []}
                    let setting = tempoSettings.find(s => s.year === year);
                    if (setting) {
                        perYearDetailsProv[year]["hoursPerDay"] = setting.hoursPerDay;
                        perYearDetailsProv[year]["percentageRequired"] = setting.percentageRequired;
                        perYearDetailsProv[year]["fromDate"] = setting.fromDate;
                    }
                }

                perYearDetailsProv[year]["details"].push(tempo);
            });

            let perYearProvList = [];
            Object.keys(perYearProv).forEach(year => perYearProvList.push(perYearProv[year]));
            setPerYearList(perYearProvList);

            let perYearPerPlaceList = [];
            Object.keys(perYearPlaceProv).forEach(year => perYearPerPlaceList.push(perYearPlaceProv[year]));
            setPerYearAndPlace(perYearPerPlaceList);

            let perYearDetailsList = [];
            Object.keys(perYearDetailsProv).forEach(year => perYearDetailsList.push(perYearDetailsProv[year]));
            setPerYearDetails(perYearDetailsList);
        }
    }, [tempoSettings, tempoList])

    return (
        <>
            <Row>
                {perYearList && perYearList.sort((py2, py1) => py1.year-py2.year).map((perYear) => (
                    <Col lg={3} key={perYear.year}>
                        <TempoTimeUsed perYear={perYear}/>
                    </Col>
                ))}
            </Row>
            <Row>
                {perYearDetails && perYearDetails.sort((py2, py1) => py1.year-py2.year).map((perYearDetail) => (
                    <Col lg={12} key={perYearDetail.year}>
                        <TempoTimeDetailPerYear perYearDetail={perYearDetail}/>
                    </Col>
                ))}
            </Row>
            <Row>
                {perYearAndPlace && perYearAndPlace.sort((py2, py1) => py1.year-py2.year).map((perYear) => (
                    <Col lg={6} key={perYear.year}>
                        <TempoMapIndicator perYearAndPlace={perYear}/>
                    </Col>
                ))}

            </Row>
        </>
    )
}

export default TempoStatsIndicator;