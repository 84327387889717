import React, {useEffect, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import {Form, Alert, Container, Row, Col} from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useUserAuth } from "../context/UserAuthContext";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [authPersistence, setAuthPersistence] = useState(false)
    const [error, setError] = useState("");
    const { logIn, user } = useUserAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        try {
            await logIn(email, password, authPersistence);
            navigate("/home");
        } catch (err) {
            setError(err.message);
        }
    };

    useEffect(() => {
        if (user) {
            navigate("/home");
        }
    }, [user])

    return (
        <>
            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={4}>
            <div className="p-4 box">
                <h2 className="mb-3">Tempo App Login</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                            type="email"
                            placeholder="Email address"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="forAuthPersistence">
                        <Form.Check
                            type="checkbox"
                            id="auth_persistence"
                            label="Stay connected"
                            onChange={(e) => setAuthPersistence(e.target.checked)}
                        />
                    </Form.Group>

                    <div className="d-grid gap-2">
                        <Button variant="primary" onClick={handleSubmit}>
                            Log In
                        </Button>
                    </div>
                </Form>
            </div>
            <div className="p-4 box mt-3 text-center">
                Don't have an account? <Link to="/signup">Sign up</Link>
            </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Login;