import TempoSettingForm from "./TempoSettingForm";
import {Card, Col, Container, Row} from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";

const TempoSettings = ({tempoSettings}) => {

    return (
        <>
            <Container>
                <Row>
                    <Col lg={3}>
                        <Card>
                            <CardHeader>Add a new deal</CardHeader>
                            <Card.Body>
                                <TempoSettingForm/>
                            </Card.Body>
                        </Card>
                    </Col>
                    {tempoSettings && tempoSettings.map((setting) => (
                        <Col lg={3} key={setting.id}>
                            <Card>
                                <CardHeader>Deal {setting.year}</CardHeader>
                                <Card.Body>
                                    <TempoSettingForm uidIn={setting.id} hourRequiredIn={setting.hourRequired}
                                                      yearIn={setting.year} hoursPerDayIn={setting.hoursPerDay} holidaysIn={setting.holidays} fromDateIn={setting.fromDate} percentageRequiredIn={setting.percentageRequired}
                                                      overtimeDays={setting.overtimeDays}/>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </>
    )

}

export default TempoSettings;