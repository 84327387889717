import {Button, Card, Table} from "react-bootstrap";
import TempoForm from "./TempoForm";
import {useState} from "react";
import {doc} from "firebase/firestore";
import {db} from "../firebase-config";
import {deleteDoc} from "@firebase/firestore";

const TempoHistory = ({tempoList}) => {

    const [selectedTempo, setSelectedTempo] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const editTempo = (tempo) => {
        setSelectedTempo(tempo);
        setIsEditing(true);
    }
    const deleteTempo = (tempo) => {
        const docRef = doc(db, "tempo", tempo.id);

        deleteDoc(docRef)
            .then(() => {
            })
            .catch(error => {
                console.log(error);
            })
    }

    return (
        <>
            {isEditing &&

                <TempoForm tempo={selectedTempo} isFinishedSaving={() => setIsEditing(false)} />

            }


            <Table className="mt-2" striped bordered hover>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Task</th>
                    <th>Duration</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Overtime</th>
                    <th>Holiday</th>
                    <th>County</th>
                    <th>Edit</th>
                    <th>Delete</th>
                </tr>
                </thead>
                <tbody>
                {tempoList && tempoList.sort((tempo1, tempo2) => tempo2.date.localeCompare(tempo1.date)).map((tempo) => (
                    <tr key={tempo.id}>
                        <td>{tempo.name}</td>
                        <td>{tempo.date}</td>
                        <td>{tempo.task}</td>
                        <td>{tempo.from}</td>
                        <td>{tempo.to}</td>
                        <td>{tempo.duration}</td>
                        <td>{tempo.overtime}</td>
                        <td>{tempo.holiday ? "Yes" : "No"}</td>
                        <td>{tempo.county}</td>
                        <td><Button variant="success" onClick={() => editTempo(tempo)}>Edit</Button></td>
                        <td><Button variant="danger" onClick={() => deleteTempo(tempo)}>Delete</Button></td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </>
    )
}

export default TempoHistory;
