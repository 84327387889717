import {Container, Tab, Tabs} from "react-bootstrap";
import TempoForm from "./TempoForm";
import {useEffect, useState} from "react";
import {useUserAuth} from "../context/UserAuthContext";
import {collection, query,  getDocs, onSnapshot, where} from "firebase/firestore";
import {db} from '../firebase-config';
import TempoStats from "./TempoStats";
import TempoSettings from "./TempoSettings";
import TempoHistory from "./TempoHistory";


const TempoDashboard = () => {

    const [tempoList, setTempoList] = useState(JSON.parse(localStorage.getItem("tempo-list")) || []);
    const [tempoSettings, setTempoSettings] = useState(JSON.parse(localStorage.getItem("tempo-settings")) || []);
    const {user} = useUserAuth();

    const updateTempoList = (list) => {
        localStorage.setItem("tempo-list", JSON.stringify(list));
        setTempoList(list);
    }

    const updateTempoSettings = (settings) => {
        localStorage.setItem("tempo-settings", JSON.stringify(settings));
        setTempoSettings(settings);
    }

    const fetchTempos = async () => {
        if (user && user.uid) {
            const ref = query(
                collection(db, "tempo"),
                where("owner", "==", user.uid)
            );

            await getDocs(ref)
                .then((querySnapshot) => {
                    const newData = querySnapshot.docs
                        .map((doc) => ({...doc.data(), id: doc.id}));
                    updateTempoList(newData);
                }).then(() => {
                    const unsubscribe = onSnapshot(ref, (snapshot) => {
                        if (snapshot.size) {
                            let newReminders = []
                            snapshot.forEach(doc => {
                                let d = {...doc.data()};
                                d["id"] = doc.id;
                                newReminders.push(d);
                            })
                            updateTempoList(newReminders);
                        } else {
                            updateTempoList([]);
                        }
                    });
                })
        }

    }

    const fetchSettings = async () => {
        if (user && user.uid) {
            const ref = query(
                collection(db, "tempo_settings"),
                where("owner", "==", user.uid)
            );

            await getDocs(ref)
                .then((querySnapshot) => {
                    const newData = querySnapshot.docs
                        .map((doc) => ({...doc.data(), id: doc.id}));
                    updateTempoSettings(newData);
                }).then(() => {
                    const unsubscribe = onSnapshot(ref, (snapshot) => {
                        if (snapshot.size) {
                            let newReminders = []
                            snapshot.forEach(doc => {
                                let d = {...doc.data()};
                                d["id"] = doc.id;
                                newReminders.push(d);
                            })
                            updateTempoSettings(newReminders);
                        } else {
                            updateTempoSettings([]);
                        }
                    });
                })
        }

    }

    useEffect(() => {
        if (user) {
            fetchTempos().then(() => console.log("Loaded"));
            fetchSettings().then(() => console.log("Loaded"));
        }
    }, [user])

    return (
        <Container>
            <Tabs
                defaultActiveKey="home"
                id="tempo-tabs"
                className="mb-3 mt-3"
            >
                <Tab eventKey="home" title="Home">
                    <TempoStats tempoList={tempoList} tempoSettings={tempoSettings} />
                </Tab>
                <Tab eventKey="add_new" title="Add new">
                    <TempoForm />
                </Tab>
                <Tab eventKey="history" title="History">
                    <TempoHistory tempoList={tempoList} />
                </Tab>
                <Tab eventKey="setting" title="Settings">
                    <TempoSettings tempoSettings={tempoSettings}/>
                </Tab>
            </Tabs>
        </Container>
    )
}

export default TempoDashboard;