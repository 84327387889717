import React, {useEffect, useState} from 'react';

import {db} from '../firebase-config';
import {collection, addDoc, doc, setDoc} from "firebase/firestore";
import {useUserAuth} from "../context/UserAuthContext";
import {Button, Form, Toast, ToastContainer} from "react-bootstrap";
import {deleteDoc} from "@firebase/firestore";

const TempoSettingForm = ({uidIn, yearIn, overtimeDaysIn, hourRequiredIn, percentageRequiredIn, fromDateIn, hoursPerDayIn, holidaysIn}) => {

    var startOfYear = new Date();
    startOfYear.setMonth(0, 1);

    const [hoursPerDay, setHoursPerDay] = useState(hoursPerDayIn || 7.5)
    const [holidays, setHolidays] = useState(holidaysIn || 25)
    const [year, setYear] = useState(yearIn || new Date().getFullYear())
    const [fromDate, setFromDate] = useState(fromDateIn || startOfYear.toISOString().slice(0, 10));
    const [hourRequired, setHourRequired] = useState(hourRequiredIn || 0)
    const [percentageRequired, setPercentageRequired] = useState(percentageRequiredIn || 50)
    const [overtimeDays, setOvertimeDays] = useState(overtimeDaysIn || 27.5)
    const [showError, setShowError] = useState(false);
    const toggleShowError = () => setShowError(false);

    const {user} = useUserAuth();

    const calculateHours = () => {

        var start = fromDate;
        var end = new Date(start);
        end.setMonth(11, 31);

        let totalHours = 0.;

        var loop = new Date(start);
        while(loop <= end){
            let day = loop.getDay();
            if (day !== 0 && day !== 6) {
                totalHours += +hoursPerDay;
            }
            var newDate = loop.setDate(loop.getDate() + 1);
            loop = new Date(newDate);
        }

        totalHours -= (+holidays * +hoursPerDay);

        let hoursToWork = totalHours * percentageRequired / 100;
        setHourRequired(hoursToWork);
    }

    const handleChangeYear = (e) => {
        e.preventDefault(); // prevent the default action
        setYear(+e.target.value); // set name to e.target.value (event)
    };

    const handleChangePercentageRequired = (e) => {
        e.preventDefault();
        setPercentageRequired(e.target.value);
    }

    const handleChangeFromDate = (e) => {
        e.preventDefault();
        setFromDate(e.target.value);
    }

    const handleChangeHoursPerDay = (e) => {
        e.preventDefault();
        setHoursPerDay(e.target.value);
    }

    const handleChangeHolidays = (e) => {
        e.preventDefault();
        setHolidays(e.target.value);
    }

    const handleChangeOvertimeDays = (e) => {
        e.preventDefault();
        setOvertimeDays(e.target.value);
    }

    const submit = async (e) => {
        e.preventDefault();

        if (!year || !hourRequired) {
            setShowError(true);
            return;
        }

        if (uidIn)  {
            await updateSetting();
        } else {
            await addSetting();
        }
    }

    const updateSetting = async (e) => {
        const docRef = doc(db, "tempo_settings", uidIn);
        setDoc(docRef, {
            year: year,
            hourRequired: hourRequired,
            percentageRequired: percentageRequired,
            fromDate: fromDate,
            hoursPerDay: hoursPerDay,
            overtimeDays: overtimeDays,
            holidays: holidays,
            lastUpdate: Date.now(),
        }, {merge: true})
            .then(docRef => {
            })
            .catch(error => {
                console.log(error);
            })
    }

    const addSetting = async (e) => {

        try {
            let now = Date.now();
            const docRef = await addDoc(collection(db, "tempo_settings"), {
                year: year,
                hourRequired: hourRequired,
                percentageRequired: percentageRequired,
                fromDate: fromDate,
                hoursPerDay: hoursPerDay,
                overtimeDays: overtimeDays,
                holidays: holidays,
                createdAt : now,
                owner : user.uid
            });
            console.log("Document written with ID: ", docRef.id);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    const deleteSetting = async () => {
        const docRef = doc(db, "tempo_settings", uidIn);

        deleteDoc(docRef)
            .then(() => {
                console.log("Document has been deleted successfully");
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        calculateHours()
    }, [percentageRequired, fromDate, hoursPerDay, holidays]);

    return (
        <>
            <Form>
                <Form.Group className="mb-3" controlId="forYear">
                    <Form.Label>Year</Form.Label>
                    <Form.Control value={year} type="number" min="2020" max="2100" step="1" placeholder="Enter year" onChange={handleChangeYear}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="forHoursRequired">
                    <Form.Label>Hours required</Form.Label>
                    <Form.Control value={hourRequired} type="number" min="0" step="1" placeholder="Enter hours required" disabled/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="forPercentRequired">
                    <Form.Label>Percentage</Form.Label>
                    <Form.Control value={percentageRequired} type="number" min="0" max="100" step="1" placeholder="Enter percentage worked" onChange={handleChangePercentageRequired}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="fromDate">
                    <Form.Label>From</Form.Label>
                    <Form.Control value={fromDate} type="date" placeholder="Enter from date" onChange={handleChangeFromDate}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="forHoursPerDay">
                    <Form.Label>Hours per day</Form.Label>
                    <Form.Control value={hoursPerDay} type="number" min="0" max="24" placeholder="Enter hours per day" onChange={handleChangeHoursPerDay}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="forOvertimeDays">
                    <Form.Label>Overtime days</Form.Label>
                    <Form.Control value={overtimeDays} type="number" min="0" step="0.5" placeholder="Enter number of overtime days" onChange={handleChangeOvertimeDays}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="forHolidays">
                    <Form.Label>Holidays</Form.Label>
                    <Form.Control value={holidays} type="number" min="0" step="1" placeholder="Enter number of holidays" onChange={handleChangeHolidays}/>
                </Form.Group>

                <Button className="me-2" variant="success" type="submit" onClick={submit}>
                    {uidIn ? "Update" : "Create" }
                </Button>
                {uidIn && <Button variant="danger" type="submit" onClick={deleteSetting}>
                    Delete
                </Button>}
            </Form>
            <ToastContainer className="p-3" position="top-end">
                <Toast show={showError} onClose={toggleShowError}>
                    <Toast.Header>
                        <strong className="me-auto">Error</strong>
                    </Toast.Header>
                    <Toast.Body>Please fill the form before creating a new deal.</Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    )
}

export default TempoSettingForm