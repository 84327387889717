import CardHeader from "react-bootstrap/CardHeader";
import {Card} from "react-bootstrap";

const TempoTimeUsed = ({perYear}) => {
    let usedHours = Math.floor(perYear.minutes / 60);
    let usedMinutes = perYear.minutes % 60;
    let leftTime = perYear.required * 60 - perYear.minutes;
    let leftHours = Math.floor(leftTime / 60);
    let leftMinutes = leftTime % 60;

    let usedHoursOvertime = Math.floor(perYear.overtime / 60);
    let usedMinutesOvertime = perYear.overtime % 60;
    let leftOvertime = perYear.overtime_available * 60 - perYear.overtime;
    let leftOvertimeHours = Math.floor(Math.abs(leftOvertime) / 60);
    let leftOvertimeMinutes = Math.abs(leftOvertime) % 60;

    let usedHoursHolidays = Math.floor(perYear.holidays / 60);
    let usedMinutesHolidays = perYear.holidays % 60;
    let leftHolidays = perYear.holidays_available * 60 - perYear.holidays;
    let leftHolidaysHours = Math.floor(Math.abs(leftHolidays) / 60);
    let leftHolidaysMinutes = Math.abs(leftHolidays) % 60;
    return (
        <>
            <Card>
                <CardHeader className="bg-light">{perYear.year}</CardHeader>
                <Card.Body>
                    <span>Hours worked : {usedHours}h{usedMinutes}m</span>
                    <br />
                    <span>Hours left to work : {leftHours}h{leftMinutes}m</span>
                    <br />
                    <span>Hours required : {perYear.required}h</span>
                    <hr />
                    <span>Overtime worked : {usedHoursOvertime}h{usedMinutesOvertime}m</span>
                    <br />
                    <span>Hours left for overtime : {leftOvertimeHours}h{leftOvertimeMinutes}m</span>
                    <br />
                    <span>Overtime total : {perYear.overtime_available}h</span>
                    <hr />
                    <span>Holidays used : {usedHoursHolidays}h{usedMinutesHolidays}m</span>
                    <br />
                    <span>Holidays left : {leftHolidays > 0 ?"" : "-"}{leftHolidaysHours}h{leftHolidaysMinutes}m</span>
                    <br />
                    <span>Holidays total : {perYear.holidays_available}h</span>
                </Card.Body>
            </Card>
        </>
    )
}

export default TempoTimeUsed;