import {Button, Container, Nav, Navbar} from "react-bootstrap";
import {useUserAuth} from "../context/UserAuthContext";
import {useNavigate} from "react-router";

const NavigationBar = () => {

    const {logOut, user} = useUserAuth();
    const navigate = useNavigate();
    const handleLogout = async () => {
        try {
            await logOut();
            navigate("/");
        } catch (error) {
            console.log(error.message);
        }
    };

    return (
        <Navbar bg="light">
            <Container>
                <Navbar.Brand>Tempo</Navbar.Brand>
                <Button variant="primary" onClick={handleLogout}>
                    Log out
                </Button>
            </Container>
        </Navbar>
    );
}

export default NavigationBar